.wrapper-register {
    background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
    height: 100vh;
    .form-register {
        width: 500px;
        margin: 0 auto;
        padding-top: 120px;
        h2 {
            margin-bottom: 80px;
            font-size: 36px;
            text-align: center;
        }
        form {
            label {
                font-size: 15px !important;
            }
            .btn-register {
                width: 100%;
                margin: 30px 0 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
