.tableInfor {
    width: 100%;

    .tableInfor-wrap {
        display: flex;
        column-gap: 24px;

        .tableInfor-group {
            background: #fafafa;
            flex: 1 1;
            padding: 16px;

            h2 {
                font-weight: 600;
                font-size: 24px;
            }

            .personalGroup {
                display: flex;
                flex-direction: column;
                row-gap: 12px;
                height: 100%;
                margin-top: 18px;
                font-size: 14px;
                width: 100%;

                .row-group {
                    display: flex;
                    align-items: center;

                    label {
                        min-width: 40%;
                    }

                    span {
                        display: block;
                    }
                }

                a {
                    text-decoration: underline;
                }

                a.home_url {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    visibility: visible;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
        }
    }
}
