@import '../Customer/mixin';

th {
  font-weight: 500 !important;
}

// =====================================
.header {
  padding: 12px 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #001628;
  .logo {
    font-size: 20px;
    color: #fff;
    font-weight: 500;
  }
  .header-right {
    display: flex;
    align-items: center;
    .helper {
      margin-right: 20px;
      font-size: 16px;
      color: #fff;
      font-weight: 500;
    }
    .icon-bell {
      font-size: 20px;
      color: #fff;
      cursor: pointer;
    }
    .logout {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      button {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px;
        color: #001628;
        font-weight: 500;
        svg {
          fill: #001628;
        }
      }
    }
  }
}

.title-view {
  margin: 0;
  font-size: 30px;
}

.list-btn {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: normal;
  .right {
    display: flex;
    justify-content: flex-end;
    align-items: normal;
    .btn-filter {
      margin: 0 15px;
    }
  }
}

.manager-products {
  table {
    th,
    td {
      text-align: center !important;
    }
  }
}

.content-wrap {
  margin: 0;
  padding: 30px 0;
  .table-wrap {
    p {
      margin: 0 20px;
      font-size: 25px;
      button {
        margin-left: 15px;
      }
    }
  }
}

.btn-generate,
.btn-save {
  margin: 0 auto;
  button {
    display: inline-block;
  }
}

.list-render {
  .select-face {
    .face {
      padding: 0.8rem 2rem;
      @include font(1.5rem, 500, 1.8);
      transition: all 0.3s ease;
      cursor: pointer;
      &:first-child {
        border: 1px solid #dbdbdb;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:nth-child(2) {
        border: 1px solid #dbdbdb;
      }
      &:last-child {
        border: 1px solid #dbdbdb;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    .active {
      border-color: #1677ff !important;
      color: #1677ff;
    }
  }
  .title-style-colors {
    margin-top: 3rem;
    p {
      font-size: 2rem;
      font-weight: 500;
    }
  }
  .list-item-render {
    display: flex;
    justify-content: flex-start;
    align-items: normal;
    flex-wrap: nowrap;
    margin: 0 -1rem;
    overflow-x: auto;
    .item-render {
      position: relative;
      min-width: calc((100% / 6) - 2rem);
      margin: 1rem;
      padding: 2rem;
      border-radius: 10px;
      cursor: pointer;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
      transition: all 0.3s ease;
      &:hover {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      }
      .select-item-render {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 9;
      }
      .name-item-render {
        margin-top: 2rem;
        padding-top: 1rem;
        font-weight: 500;
        border-top: 1px solid #696969;
      }
      .ant-upload-list-item {
        max-width: 10rem !important;
        max-height: 10rem !important;
        overflow: hidden;
      }
      .ant-upload {
        max-width: 5rem !important;
        max-height: 5rem !important;
      }
    }
  }
}
// }

.icon-spin {
  margin: 35px auto;
}

.ant-menu-submenu {
  border-radius: 0 !important;
}

.ant-upload-list-item-container {
  width: 50% !important;
  height: 50% !important;
  margin: auto !important;
  margin-block: initial !important;
  margin-inline: initial !important;
}

.ant-upload-list-item {
  padding: 0 !important;
  border: initial !important;
}

.ant-upload-list-item {
  .anticon-eye {
    display: none;
  }
  &::before {
    width: 100% !important;
    height: 100% !important;
  }
}

.ant-image-preview-img {
  margin: 0 auto;
}

.ant-input-suffix {
  margin-inline-start: 0 !important;
}

.ant-upload-list-item-name {
  width: 2rem !important;
}

.style-color .ant-form-item {
  margin: 0 auto !important;
}

.hidden-row {
  display: none;
}

.margin-row {
  margin: 0;
}

.setting-wrap {
  padding: 0 2.5rem;
  border-radius: 8px;
  background-color: #fff;
  .setting-item {
    .ant-form-item {
      margin: 0;
      padding: 1.2rem 0;
      border-bottom: 1px solid #dedede;
    }
    &:last-child {
      .ant-form-item {
        border-bottom: 0;
      }
    }
    p {
      width: 220px;
      font-size: 1.6rem;
      font-weight: 500;
    }
    span {
      font-size: 1.4rem;
    }
    label {
      font-size: 1.4rem;
      font-weight: 500;
    }
  }
  &.setting-update {
    .setting-item {
      border-bottom: 1px solid #f4f4f4;
      padding: 1rem 0;
      &:last-child {
        border: none;
      }
      .label-setting {
        width: 220px;
        margin: 0 1rem 0 0;
      }
      .ant-form-item {
        border: none;
      }
    }
  }
  .ant-checkbox-wrapper {
    margin-inline-start: 10px;
  }
  .ant-col-16 {
    max-width: 100%;
  }
}

.border-dotted {
  border-bottom: 1px dotted #696969;
}

.color-box {
  margin: 0 auto;
  width: 2.8rem;
  height: 2.8rem;
  border: 1px solid #dbdbdb;
  border-radius: 0.6rem;
}

.d-flex-center {
  display: flex;
  align-items: center;
}
