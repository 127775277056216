.wrapper-login {
  background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
  height: 100vh;
  .form-login {
    width: 500px;
    margin: 0 auto;
    padding-top: 160px;
    h2 {
      margin-bottom: 80px;
      font-size: 36px;
      text-align: center;
    }
    form {
      label {
        font-size: 15px !important;
      }
      .option {
        display: flex;
        justify-content: space-between;
      }
      .login-form-button {
        width: 100%;
        margin: 40px 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

// Custom Alert
.ant-notification-notice-message {
  margin-bottom: 0 !important;
}
